<template>
  <div class="logout-view">
    <div class="logout-panel">

      <div class="logout-panel-text">
        Logger af...
      </div>

      <form method="POST" :action="signOutUrl" ref="logoutForm">
        <input type="hidden" name="LogoutId" v-model="logoutForm.logoutId" />
      </form>
    </div>
  </div>
</template>

<script>
import { oidcSettings } from '../../config/oidc';
import { mapActions } from 'vuex';

const authority = oidcSettings.authority;

export default {
  name: 'logout-view',
  data: () => ({
    logoutForm: {
      logoutId: (new URLSearchParams(window.location.search)).get('logoutId'),
    }
  }),
  mounted() {
    this.removeUser();
    this.$refs.logoutForm.submit();
  },
  computed: {
    signOutUrl() {
      return `${authority}/api/authorization/logout`;
    }
  },
  methods: {
    ...mapActions('oidcStore', [
      'removeUser'
    ])
  }
}
</script>

<style lang="scss" scoped>
.logout-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .logout-panel {
    flex: 1;
    display: flex;
    flex-flow: column;
    max-width: 400px;
    gap: 15px;

    .logout-panel-text {
      padding-bottom: 5px;
    }
  }
}
</style>
